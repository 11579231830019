import React from 'react';

import { isUserLoggedIn } from 'utils/authentication';

// ** custom components
import DesktopMenuLoggedIn from './menutype/DesktopMenuLoggedIn';
import DesktopMenuGuest from './menutype/DesktopMenuGuest';
import DesktopMenuCompany from './menutype/DesktopMenuCompany';

import classes from './DesktopnMenu.module.scss';

const NavigationMenu = ({ vipAccess, isB2B }) => {
	const isAuthenticated = isUserLoggedIn();
	return (
		<>
			<header className={`${classes['header-desktop']}`}>
				{!isB2B ? (
					<>
						{isAuthenticated &&
							<>
								<DesktopMenuLoggedIn vipAccess={vipAccess} />								
							</>
						}
						{!isAuthenticated && <DesktopMenuGuest />}
					</>
				) : (
					<DesktopMenuCompany />
				)}
			</header>
		</>
	);
};

export default NavigationMenu;
